import { forwardRef, useRef, useImperativeHandle, useMemo, useEffect, useState, useCallback } from 'react'
import Lottie from 'lottie-react'

//* Style
import LottieAnimationStyle from './style'

const LottieAnimation = forwardRef(({ animData, loop, autoplay, animateOnHover, className, isPlay, isRev, delayPlay }, ref) => {
    //! Refs
    const contRef = useRef()
    const lottieRef = useRef()

    useImperativeHandle(ref, () => contRef.current, [])

    //! States
    const [play, setPlay] = useState(false)
    const [isReverse, setIsReverse] = useState(false)

    //! Animation play/pause for hover
    // useEffect(() => {
    //     if (!autoplay) {
    //         if (play) {
    //             lottieRef.current.setDirection(1)
    //             lottieRef.current.play()
    //         } else if (!play && isReverse) {
    //             lottieRef.current.setDirection(-1)
    //             lottieRef.current.play()
    //         } else {
    //             lottieRef.current.pause()
    //         }
    //     }
    // }, [play, isReverse, autoplay])

    useEffect(() => {
        if (!!delayPlay) {
            setTimeout(() => {
                lottieRef.current.setDirection(1)
                lottieRef.current.play()
            }, delayPlay.timeout)
        }
    }, [delayPlay])

    //! Animation play from parent
    useEffect(() => {
        if (!autoplay && isPlay) {
            lottieRef.current.setDirection(1)
            lottieRef.current.play()
        }

        if (isRev) {
            lottieRef.current.setDirection(-1)
            lottieRef.current.play()
        }
    }, [isPlay, autoplay, isRev])

    //! Default options
    const defaultOptions = useMemo(
        () => ({
            loop: loop,
            autoplay: autoplay,
            animationData: animData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        }),
        [animData, loop, autoplay]
    )

    //! Handle Mouse Enter
    const onMouseEnter = useCallback(() => animateOnHover && setPlay(true), [animateOnHover])

    //! Handle Mouse Leave
    const onMouseLeave = useCallback(() => {
        if (animateOnHover) {
            setPlay(false)
            setIsReverse(true)
        }
    }, [animateOnHover])

    //! Handle Complete
    const onComplete = useCallback(() => {
        if (!autoplay && !isPlay) {
            lottieRef.current.pause()
        }
    }, [autoplay, isPlay])

    //! Handle Loop Complete
    const onLoopComplete = useCallback(() => {
        if (animateOnHover && isReverse) {
            setIsReverse(false)
            lottieRef.current.pause()
        }
    }, [animateOnHover, isReverse, autoplay, isPlay])

    return (
        <LottieAnimationStyle
            ref={contRef}
            className={`lottie-container ${className || ''}`}
        >
            <Lottie
                width={'auto'}
                height={'100%'}
                lottieRef={lottieRef}
                className={`lottie-wrapper`}
                onLoopComplete={onLoopComplete}
                onComplete={onComplete}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...defaultOptions}
            />
        </LottieAnimationStyle>
    )
})

export default LottieAnimation
