import styled from 'styled-components';

const FormSectionStyle = styled.div`
	--stayInTouchTitleMarginBottom: var(--sp4x);

	--touchInputHeight: var(--sp8x);
	--touchInputWidth: var(--sp87x);
	--inputBorderRadius: var(--sp4x);
	--inputPaddingLeft: var(--sp3x);
	--inputPaddingRight: var(--sp8x);

	.success-text {
		width: var(--touchInputWidth);
		margin-left: auto;
	}

	.form-block-wrapper {
		display: flex;
		justify-content: flex-end;

		.ant-form {
			&.pointer-none {
				pointer-events: none;
			}
		}

		.ant-form {
			width: var(--touchInputWidth);
		}

		.ant-form-item-explain-error {
			padding-left: var(--inputPaddingLeft);
		}
	}

	.stay-in-touch-container {
		&.active {
			.stay-in-touch-title,
			.ant-form {
				transform: translateY(0);
			}
		}

		.stay-in-touch-title {
			transform: translateY(100px);
			transition: all var(--trTime) ease-out;
			margin-bottom: var(--stayInTouchTitleMarginBottom);
			text-align: right;
		}

		.ant-form {
			position: relative;
			height: var(--touchInputHeight);
			transform: translateY(100px);
			transition: all calc(var(--trTime) * 2) ease-out;

			.touch-email-field {
				.ant-input {
					border: 2px solid var(--green);
					border-radius: var(--inputBorderRadius);
					padding-left: var(--inputPaddingLeft);
					padding-right: var(--inputPaddingRight);
					width: 100%;
					height: var(--touchInputHeight);
					color: var(--black);
					font-weight: 500;
					font-size: var(--h3);
					padding-bottom: 0;

					&::placeholder {
						color: var(--black);
						font-weight: 500;
						font-size: var(--h3);
					}
				}
			}

			.email-submit-button {
				position: absolute;
				top: 50%;
				height: var(--h2L);
				right: var(--inputPaddingLeft);
				transform: translateY(-50%);
				cursor: pointer;

				&.hide {
					height: unset;

					i {
						display: none;
					}
				}
			}
		}
	}

	//! Error
	.ant-form-item-has-error {
		input {
			border: 2px solid var(--coral) !important;
		}
	}

	//! Global error
	.form-block-wrapper {
		position: relative;

		.error-text {
			position: absolute;
			top: 100%;
			left: calc(100% - var(--touchInputWidth) + var(--inputPaddingLeft));
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--stayInTouchTitleMarginBottom: var(--sp4x);
		--touchInputHeight: var(--sp8x);
		--touchInputWidth: var(--sp78x);
		--inputPaddingLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--stayInTouchTitleMarginBottom: var(--sp4x);
		--touchInputHeight: var(--sp8x);
		--touchInputWidth: var(--sp78x);
		--inputPaddingLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--stayInTouchTitleMarginBottom: var(--sp3x);
		--touchInputHeight: var(--sp7x);
		--touchInputWidth: var(--sp69x);
		--inputPaddingLeft: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--stayInTouchTitleMarginBottom: var(--sp2x);
		--touchInputHeight: var(--sp7x);
		--touchInputWidth: 100%;
		--inputPaddingLeft: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--stayInTouchTitleMarginBottom: var(--sp2x);
		--touchInputHeight: var(--sp7x);
		--touchInputWidth: 100%;
		--inputPaddingLeft: var(--sp2-5x);
	}
`;

export default FormSectionStyle;
