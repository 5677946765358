import { useRef, useState } from 'react';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Components
import Icon from '@/components/common/Icon';
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import FormItem from '@/components/common/Form/FormItem';
import FormInput from '@/components/common/Form/FormInput';
import ButtonLoading from '@/components/common/ButtonLoading';
import FormContainer from '@/components/common/Form/FormContainer';

//* Style
import FormSectionStyle from './style';

const FormSection = ({ translate, subscribe }) => {
	//! Refs
	const sectionRef = useRef();
	const activeSection = useActiveSection(sectionRef, 80);

	//! State
	const [isSuccess, setIsSuccess] = useState(false);

	//! Handle Finish
	const handleFinish = async ({ subscribe_email }) => {
		setIsSuccess('loading');

		return await subscribe({ email: subscribe_email })
			.then(() => {
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
				}, 3000);

				return Promise.resolve();
			})
			.catch((err) => {
				setIsSuccess(false);

				return Promise.reject(err);
			});
	};

	return (
		<FormSectionStyle>
			<Container
				section
				ref={sectionRef}
				className={`stay-in-touch-container ${activeSection ? 'active' : ''}`}>
				<Text
					className={'h1 font-futura-medium stay-in-touch-title'}
					text={'stayInTouch'}
				/>

				{isSuccess && isSuccess !== 'loading' ? (
					<Text
						text='subscribeThankYouMessage'
						className='green h4 success-text font-futura-regular'
					/>
				) : (
					<FormContainer
						onFinish={handleFinish}
						className={isSuccess === 'loading' ? 'pointer-none' : ''}>
						<FormItem
							name={'subscribe_email'}
							validationType={'email'}>
							<FormInput
								className={'touch-email-field'}
								placeholder={translate('email')}
							/>
						</FormItem>

						<button
							type='submit'
							id='subscribe-button'
							title='subscribe-icon'
							aria-label='subscribe-button-icon'
							className={`email-submit-button ${isSuccess === 'loading' ? 'hide' : ''}`}>
							<Icon
								className={'h2-L'}
								name={'arrow-big'}
							/>

							{isSuccess === 'loading' ? (
								<div className='loader-wrapper'>
									<ButtonLoading />
								</div>
							) : null}
						</button>
					</FormContainer>
				)}
			</Container>
		</FormSectionStyle>
	);
};

export default withDataContext(withLanguageContext(FormSection, ['translate']), ['subscribe']);
