import { useRef, useEffect } from 'react';
import { ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import ScrollTextStyle from './style';

//* Components
import Container from '@/components/common/Container';
import Text from '@/components/common/Text';

const ScrollText = ({ translate, isSection = true }) => {
	//! Refs
	const sectionRef = useRef();
	const titlesWrapRef = useRef();
	const title1Ref = useRef();
	const title2Ref = useRef();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		gsap.from(title1Ref.current, {
			x: '-150%',
		});
		gsap.to(title1Ref.current, {
			scrollTrigger: {
				trigger: title1Ref.current,
				end: 'bottom 0%',
				start: 'bottom 100%',
				scrub: true,
			},
			x: '0',
		});
		gsap.from(title2Ref.current, {
			x: '100%',
		});
		gsap.to(title2Ref.current, {
			scrollTrigger: {
				trigger: title2Ref.current,
				end: 'top 0%',
				start: 'top 100%',
				scrub: true,
				// markers: true,
			},
			x: '0',
		});
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
		};
	}, [title2Ref.current, title1Ref.current]);

	return (
		<ScrollTextStyle ref={sectionRef}>
			<Container
				section={isSection}
				full>
				<div
					className={'titles'}
					ref={titlesWrapRef}>
					<Text
						className={'h1 font-futura-light'}
						ref={title1Ref}>
						<span className='font-futura-semibold'>{translate('free')}</span> {translate('shipping')}
					</Text>
					<Text
						ref={title2Ref}
						className={'h1 font-futura-light'}>
						{translate('onAllOrdersOver')}
					</Text>
				</div>
			</Container>
		</ScrollTextStyle>
	);
};

export default withLanguageContext(ScrollText, ['translate']);
