import styled from 'styled-components';

const FormItemStyle = styled.div`
	.ant-form-item-has-error {
		input,
		textarea {
			border-bottom: 1px solid var(--coral) !important;
			color: var(--coral) !important;

			&::placeholder {
				color: var(--coral) !important;
			}
		}

		label {
			color: var(--coral) !important;
		}

		.ant-select-selection-placeholder,
		.select-arrow {
			color: var(--coral) !important;
		}
	}

	.ant-form-item-explain,
	.ant-form-item-extra {
		clear: both;
		color: var(--coral);
		font-size: var(--p);
		font-family: var(--futura);
		font-weight: 400;
		line-height: var(--lineHeight);
		transform: translateY(var(--sp1x));
		transition: color var(--trTime) cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	.ant-form-item-with-help .ant-form-item-explain {
		height: auto;
		opacity: 1;
	}
	.ant-show-help {
		transition: opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.ant-show-help-appear,
	.ant-show-help-enter {
		opacity: 0;
	}
	.ant-show-help-appear-active,
	.ant-show-help-enter-active {
		opacity: 1;
	}
	.ant-show-help-leave {
		opacity: 1;
	}
	.ant-show-help-leave-active {
		opacity: 0;
	}
	.ant-show-help-item {
		overflow: hidden;
		transition: height var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), transform var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-show-help-item-appear,
	.ant-show-help-item-enter {
		transform: translateY(-8px);
		opacity: 0;
	}
	.ant-show-help-item-appear-active,
	.ant-show-help-item-enter-active {
		transform: translateY(0);
		opacity: 1;
	}
	.ant-show-help-item-leave {
		transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-show-help-item-leave-active {
		transform: translateY(-8px);
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
	}
`;

export default FormItemStyle;
