import styled from 'styled-components';

const WhyZoyasSectionStyle = styled.div`
	--lottiesWrapMaxWidth: 80%;
	--lottieTextMarginTop: var(--sp8x);
	--singleLottieMarginRight: var(--sp8x);
	--titleMaxWidth: var(--sp17x);
	--lottieWidth: 80%;
	--centerLottieMarginTop: var(--sp40x);

	&.active {
		.why-zoyas-container {
			transform: translateY(0);
		}
	}

	.why-zoyas-container {
		display: flex;
		justify-content: space-between;
		transform: translateY(100px);
		transition: 0.6s ease;

		.why-zoyas-title {
			max-width: var(--titleMaxWidth);
		}

		.lotties-wrap {
			display: flex;
			max-width: var(--lottiesWrapMaxWidth);
			width: 100%;

			.single-lottie-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: calc(100% / 3);
				margin-right: var(--singleLottieMarginRight);

				.lottie-container {
					width: var(--lottieWidth);
				}

				.lottie-text {
					margin-top: var(--lottieTextMarginTop);
				}

				&.center {
					margin-top: var(--centerLottieMarginTop);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--lottiesWrapMaxWidth: 75%;
		--lottieTextMarginTop: var(--sp7x);
		--singleLottieMarginRight: var(--sp8x);
		--lottieWidth: 80%;
		--centerLottieMarginTop: var(--sp25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--lottiesWrapMaxWidth: 78%;
		--lottieTextMarginTop: var(--sp6x);
		--singleLottieMarginRight: var(--sp5x);
		--lottieWidth: 80%;
		--centerLottieMarginTop: var(--sp23x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--lottiesWrapMaxWidth: 75%;
		--lottieTextMarginTop: var(--sp6x);
		--singleLottieMarginRight: var(--sp5x);
		--lottieWidth: 80%;
		--centerLottieMarginTop: var(--sp20x);

		.single-lottie-wrap {
			&.center {
				.lottie-container {
					width: 95% !important;
				}
			}

			&.right {
				margin-top: var(--sp7x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--lottiesWrapMaxWidth: 80%;
		--lottieTextMarginTop: var(--sp4x);
		--singleLottieMarginRight: var(--sp2x);
		--lottieWidth: 90%;
		--centerLottieMarginTop: var(--sp14x);

		.single-lottie-wrap {
			&.center {
				.lottie-container {
					width: 100% !important;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--lottiesWrapMaxWidth: 80%;
		--lottieTextMarginTop: var(--sp2x);
		--singleLottieMarginRight: 0;
		--lottieWidth: var(--sp20x);
		--centerLottieMarginTop: 0;

		.why-zoyas-container {
			flex-direction: column;

			.why-zoyas-title {
				margin-bottom: var(--sp2x);
			}

			.lotties-wrap {
				margin: 0 auto;
				flex-direction: column;

				.single-lottie-wrap {
					margin-top: var(--sp2x);
					width: fit-content;

					&.center {
						margin-top: var(--sp2x);
						margin-left: auto;
					}
				}
			}
		}
	}
`;

export default WhyZoyasSectionStyle;
