import { useRef } from 'react'

//* Helpers
import { useActiveSection } from '@/helpers'

//* Lottie's
import Pig from '@/lottieFiles/pig.json'
import Milk from '@/lottieFiles/milk.json'
import Rocket from '@/lottieFiles/rocket.json'

//* Components
import Container from '@/components/common/Container'
import Text from '@/components/common/Text'
import LottieAnimation from '@/components/common/LottieAnimation'

//* Style
import WhyZoyasSectionStyle from './style'

const WhyZoyasSection = () => {
	//! Refs
	const sectionRef = useRef()
	const activeSection = useActiveSection(sectionRef, 80)

	return (
		<WhyZoyasSectionStyle
			className={`${activeSection ? 'active' : ''}`}
			ref={sectionRef}
		>
			<Container
				className={'why-zoyas-container'}
				section
			>
				<Text
					className={'h1 font-futura-medium why-zoyas-title'}
					text={'whyZoyas'}
				/>
				<div className='lotties-wrap'>
					<div className='single-lottie-wrap left'>
						<LottieAnimation
							animData={Pig}
							autoplay={false}
							isPlay={activeSection}
							loop={false}
							isRev={!activeSection}
						/>
						<Text
							className={'h2 font-futura-medium lottie-text'}
							text={'affordable'}
						/>
					</div>
					<div className='single-lottie-wrap center'>
						<LottieAnimation
							animData={Milk}
							autoplay={false}
							isPlay={activeSection}
							loop={false}
							isRev={!activeSection}
						/>
						<Text
							className={'h2 font-futura-medium lottie-text'}
							text={'wideRange'}
						/>
					</div>
					<div className='single-lottie-wrap right'>
						<LottieAnimation
							animData={Rocket}
							autoplay={false}
							isPlay={activeSection}
							loop={false}
							isRev={!activeSection}
						/>
						<Text
							className={'h2 font-futura-medium lottie-text'}
							text={'fastDelivery'}
						/>
					</div>
				</div>
			</Container>
		</WhyZoyasSectionStyle>
	)
}

export default WhyZoyasSection
