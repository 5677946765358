import styled from 'styled-components';

const FormInputStyle = styled.div`
	--inputPadBottom: var(--sp1x);
	--textareaHeight: var(--sp12x);

	input,
	textarea {
		padding: 0;
		padding-bottom: var(--inputPadBottom);
		border-radius: 0;
		background-color: transparent;
		border: unset;
		border-bottom: 1px solid var(--grey);
		width: 100%;
		font-size: var(--sp2x);
		font-family: var(--futura);
		font-weight: 400;
		line-height: var(--lineHeight);
		color: var(--black);
		transition: unset;

		&::placeholder {
			font-size: var(--sp2x);
			font-family: var(--futura);
			font-weight: 400;
			line-height: var(--lineHeight);
			color: var(--darkGrey);
			/* color: var(--black); */
		}
	}

	textarea {
		height: var(--textareaHeight);
		min-height: var(--textareaHeight);
		max-height: var(--textareaHeight);
	}

	.ant-input-suffix,
	.ant-input-textarea-suffix {
		display: none;
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
	}
`;

export default FormInputStyle;
