import styled from 'styled-components';

const ScrollTextStyle = styled.div`
	--titleMaxWidth: var(--sp96x);

	.titles {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		white-space: nowrap;
		margin: 0 auto;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--titleMaxWidth: var(--sp70x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMaxWidth: var(--sp70x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleMaxWidth: var(--sp64x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--titleMaxWidth: var(--sp64x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleMaxWidth: var(--sp64x);
	}
`;

export default ScrollTextStyle;
